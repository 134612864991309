// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from '../app/_shared/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAF_76vj2kU97q3tLQJP3jqrCDX3axX9Vo",
    authDomain: "blush-qa.firebaseapp.com",
    projectId: "blush-qa",
    storageBucket: "blush-qa.appspot.com",
    messagingSenderId: "386521661036",
    appId: "1:386521661036:web:e870bc7e139fc3de0d2249",
    measurementId: "G-K0E6VK7CPK",
    databaseURL: "https://blush-qa-default-rtdb.firebaseio.com"
  },
  apiUrl: 'https://us-central1-blush-qa.cloudfunctions.net',
  apiPosUrl: 'https://us-central1-blush-qa.cloudfunctions.net',
  // mapsApiKey: 'AIzaSyBs4kc0MSe-kxU8w2lrDfhauw2Xfq_yqKc',
  mapsApiKey: 'AIzaSyDrYHsmCJoPhjc9RE-m3ykME5ZmOGwLVeY',
  release: '00.3.31',
  baseUrl: 'https://qa-blush-cms.web.app',
  envName: 'QA',
  hoodieAPIkey: '64b0137687c0842a46a7a150ddafb443',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-dev.leafvip.app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
