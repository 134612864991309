import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { fromPromise } from 'rxjs/internal-compatibility';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { DispensaryInterface, DispensaryInterfaceWithId } from '../interfaces/dispensary.interface';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import { DispensaryPOSInterface } from '../interfaces/dispensaryPOS.interface';
import { UsStatesInterface } from '../interfaces/usStates.interface';
import firebase from 'firebase/app';
import { ContactActivityInterface } from '../interfaces/contactActivity.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DispensariesExportData } from '../interfaces/dispensaryExportReport.interface';
import { DispensaryTerritoryInterface, DispensaryTerritoryInterfaceWithId } from '../interfaces/dispensaryTerritory.interface';
import { UserDataInterfaceWithId } from '../interfaces/userData.interface';
import { RetailGroupInterfaceWithId } from '../interfaces/retailGroups.interface';

@Injectable({
	providedIn: 'root',
})
export class DispensaryService {
	constructor(private afs: AngularFirestore, private httpClient: HttpClient) { }

	getAllDispensaries(): Observable<DispensaryInterfaceWithId[]> {
		return this.afs
			.collection('dispensaries')
			.snapshotChanges()
			.pipe(
				map((a) =>
					a.map((item) => {
						const data = item.payload.doc.data() as DispensaryInterface;
						const id = item.payload.doc.id;
						const exists = item.payload.doc.exists;
						return { ...data, id, exists } as DispensaryInterfaceWithId;
					})
				)
			);
	}

	async getAllDispensariesPromise(): Promise<DispensaryInterfaceWithId[]> {
		const { docs } = await this.afs.collection('dispensaries').get().toPromise();

		return docs.map((item) => {
			const data = item.data() as DispensaryInterface;
			const id = item.id;
			const exists = item.exists;
			return { ...data, id, exists } as DispensaryInterfaceWithId;
		});
	}

	getActiveDispensaries(): Observable<DispensaryInterfaceWithId[]> {
		return this.afs
			.collection('dispensaries', (ref) => ref.where('leafUsers', '>=', 1))
			.snapshotChanges()
			.pipe(
				map((a) =>
					a
						.map((item) => {
							const data = item.payload.doc.data() as DispensaryInterface;
							const id = item.payload.doc.id;
							const exists = item.payload.doc.exists;
							return { ...data, id, exists } as DispensaryInterfaceWithId;
						})
						.filter((item) => item.leafUsers)
				)
			);
	}

	getVerifiedDispensariesWithLeafUsers(): Observable<DispensaryInterfaceWithId[]> {
		return this.afs
			.collection('dispensaries', (ref) => ref.where('status', '==', 'verified'))
			.snapshotChanges()
			.pipe(
				map((a) =>
					a
						.map((item) => {
							const data = item.payload.doc.data() as DispensaryInterface;
							const id = item.payload.doc.id;
							const exists = item.payload.doc.exists;
							return { ...data, id, exists } as DispensaryInterfaceWithId;
						})
						.filter((item) => item.leafUsers)
				)
			);
	}

	getVerifiedDispensaries(): Observable<DispensaryInterfaceWithId[]> {
		return this.afs
			.collection('dispensaries', (ref) => ref.where('status', '==', 'verified').orderBy('displayName'))
			.snapshotChanges()
			.pipe(
				map((a) =>
					a.map((item) => {
						const data = item.payload.doc.data() as DispensaryInterface;
						const id = item.payload.doc.id;
						const exists = item.payload.doc.exists;
						return { ...data, id, exists } as DispensaryInterfaceWithId;
					})
				)
			);
	}

	getVerifiedDispensariesRetailGroup(retailGroup: RetailGroupInterfaceWithId): Observable<DispensaryInterfaceWithId[]> {
		const batches$ = [];
		const dispensaries = [...retailGroup.dispensaries];

		while( dispensaries.length > 0 ) {
			const batch = dispensaries.splice(0, 10);
			batches$.push(
				of(batch)
					.pipe(
						switchMap( ids => this.afs
								.collection(
									'dispensaries',
									(ref) => {
										return ref
											.where('status', '==', 'verified')
											.where(firebase.firestore.FieldPath.documentId(), 'in', ids)
									}
								)
								.snapshotChanges()
						),
						map((dispDocs) => dispDocs.map((dispDoc) => {
								const data = dispDoc.payload.doc.data() as DispensaryInterface;
								const id = dispDoc.payload.doc.id;
								const exists = dispDoc.payload.doc.exists;
								return { ...data, id, exists } as DispensaryInterfaceWithId;
							})
						),
					)
			);
		}

		return combineLatest( batches$).pipe(
			map((results: any) => results.flat() )
		);
	}

	getVerifiedDispensariesByState(state: UsStatesInterface): Observable<DispensaryInterfaceWithId[]> {
		return this.afs
			.collection('dispensaries', (ref) => ref.where('status', '==', 'verified').where('address.state', '==', state.abbreviation))
			.snapshotChanges()
			.pipe(
				map((a) =>
					a.map((item) => {
						const data = item.payload.doc.data() as DispensaryInterface;
						const id = item.payload.doc.id;
						const exists = item.payload.doc.exists;
						return { ...data, id, exists } as DispensaryInterfaceWithId;
					})
				)
			);
	}

	getDispensaryById(dispensaryId: string): Observable<DispensaryInterfaceWithId> {
		return this.afs
			.collection('dispensaries')
			.doc(dispensaryId)
			.snapshotChanges()
			.pipe(
				map((item) => {
					const data = item.payload.data() as DispensaryInterface;
					const id = item.payload.id;
					const exists = item.payload.exists;
					return { ...data, exists, id } as DispensaryInterfaceWithId;
				})
			);
	}

	queryStateChangesDispensaries() {
		console.log(`RUN DISPENSARIES QUERY - ${moment().format('HH:mm:ss')}`);
		return this.afs.collection('dispensaries').stateChanges();
	}

	addDispensary(dispensary: Partial<DispensaryInterfaceWithId>): Observable<DispensaryInterfaceWithId> {
		const currentDispensary = {
			...dispensary,
			lastUpdated: firebase.firestore.Timestamp.now(),
		};

		if (currentDispensary.id !== undefined) {
			delete currentDispensary.id;
		}
		if (currentDispensary.exists !== undefined) {
			delete currentDispensary.exists;
		}

		return fromPromise(this.afs.collection('dispensaries').add(currentDispensary)).pipe(
			map((res) => {
				const id = res.id;
				const exists = true;
				const newDispensary = {
					...dispensary,
					id,
					exists,
				} as DispensaryInterfaceWithId;
				return newDispensary;
			})
		);
	}

	updateDispensaryWithId(dispensary: Partial<DispensaryInterfaceWithId>, id: string): Observable<DispensaryInterfaceWithId> {
		console.log('updateDispensaryWithId executed');
		const updateDispensary = {
			...dispensary,
			pointOfSale: dispensary.pointOfSale ? dispensary.pointOfSale : '',
			lastUpdated: firebase.firestore.Timestamp.now(),
		};

		if (updateDispensary.id !== undefined) {
			delete updateDispensary.id;
		}
		if (updateDispensary.exists !== undefined) {
			delete updateDispensary.exists;
		}

		return fromPromise(this.afs.collection('dispensaries').doc(id).update(updateDispensary)).pipe(
			map(() => {
				const exists = true;
				const newDispensary = { ...updateDispensary, id, exists } as DispensaryInterfaceWithId;
				return newDispensary;
			}),
			catchError((error) => {
				console.error("Firestore update error:", error);
				throw error; // Rethrow the error after logging
			})
		);
	}

	updateDispensaryWithIdv2(
		dispensary: Partial<DispensaryInterfaceWithId>,
		id: string,
		currentUser: UserDataInterfaceWithId,
		changeLog: string,
		previousValue: any,
		currentValue: any
	) {
		const updateDispensary = {
			...dispensary,
			lastUpdated: firebase.firestore.Timestamp.now(),
		};

		if (updateDispensary.id !== undefined) {
			delete updateDispensary.id;
		}
		if (updateDispensary.exists !== undefined) {
			delete updateDispensary.exists;
		}

		const batch = this.afs.firestore.batch();
		const dispensaryRef = this.afs.firestore.collection('dispensaries').doc(id);
		const dispensaryChangeLog = this.afs.firestore.collection('dispensaries').doc(id).collection('changeLog').doc();

		let fullName = '';
		if (currentUser?.firstName) {
			fullName = currentUser.firstName;
		}
		if (currentUser?.lastName) {
			fullName = fullName + ' ' + currentUser.lastName;
		}

		batch.update(dispensaryRef, updateDispensary);
		batch.set(dispensaryChangeLog, {
			createdAt: firebase.firestore.Timestamp.now(),
			owner: fullName,
			ownerId: currentUser?.id,
			description: changeLog,
			previousValue,
			currentValue,
		});
		return fromPromise(batch.commit());
	}

	getDispensaryPos(): Observable<DispensaryPOSInterface[]> {
		return this.afs.collection('organization').doc('data').collection<DispensaryPOSInterface>('dispensary_pos').valueChanges();
	}

	getUsStates(): Observable<UsStatesInterface[]> {
		return this.afs
			.collection('organization')
			.doc('data')
			.collection<UsStatesInterface>('us_states', (ref) => ref.orderBy('name', 'asc'))
			.valueChanges();
	}

	getDispensaryTerritories(): Observable<DispensaryTerritoryInterfaceWithId[]> {
		return this.afs
			.collection('organization')
			.doc('dispensaries')
			.collection('territories')
			.snapshotChanges()
			.pipe(
				map((a) =>
					a.map((item) => {
						const data = item.payload.doc.data() as DispensaryTerritoryInterface;
						const id = item.payload.doc.id;
						const exists = item.payload.doc.exists;
						return { ...data, id, exists } as DispensaryTerritoryInterfaceWithId;
					})
				)
			);
	}

	getDispensaryTerritoryById(territoryId: string): Observable<DispensaryTerritoryInterfaceWithId> {
		return this.afs
			.collection('organization')
			.doc('dispensaries')
			.collection('territories')
			.doc(territoryId)
			.snapshotChanges()
			.pipe(
				map((item) => {
					const data = item.payload.data() as DispensaryTerritoryInterface;
					const id = item.payload.id;
					const exists = item.payload.exists;
					return { ...data, id, exists } as DispensaryTerritoryInterfaceWithId;
				})
			);
	}

	updateDispensaryTerritoryStatsById(territoryId: string): Observable<void> {
		const payload = {
			territoryId,
		};
		return this.httpClient.post<any>(`${environment.apiUrl}/cmsCalculateTerritoryStatistics`, payload).pipe(
			take(1),
			map((item) => item.payload)
		);
	}

	addDispensaryTerritories(territory: DispensaryTerritoryInterface) {
		return fromPromise(this.afs.collection('organization').doc('dispensaries').collection('territories').add(territory));
	}

	updateDispensaryTerritories(territoryUpdate: Partial<DispensaryTerritoryInterface>, territoryId: string) {
		return fromPromise(
			this.afs.collection('organization').doc('dispensaries').collection('territories').doc(territoryId).update(territoryUpdate)
		);
	}

	deleteDispensaryTerritories(territoryId: string) {
		return fromPromise(this.afs.collection('organization').doc('dispensaries').collection('territories').doc(territoryId).delete());
	}

	deleteDispensaryById(dispensaryId: string): Observable<void> {
		return fromPromise(this.afs.collection('dispensaries').doc(dispensaryId).delete());
	}

	incrementActivityEvents(contactActivity: ContactActivityInterface) {
		return fromPromise(
			this.afs
				.collection('dispensaries')
				.doc(contactActivity.dispensaryId)
				.update({
					activationEvents: firebase.firestore.FieldValue.increment(1),
					lastActivationEvent: contactActivity.contactActivityDate,
				})
		);
	}

	getDispensariesExportData(): Observable<DispensariesExportData[]> {
		return this.httpClient.post(`${environment.apiUrl}/getDispensaryExportData`, {}).pipe(
			take(1),
			map((item: any) => item.payload as DispensariesExportData[])
		);
	}

	getDispensariesExportDataV2(): Observable<DispensariesExportData[]> {
		return this.afs.collection('reports').doc('dispensaries').collection<DispensariesExportData>('exportData').valueChanges();
	}

	getDispensariesByIds(dispensaryIds: string[]): Observable<DispensaryInterfaceWithId[]> {
		return forkJoin(dispensaryIds.map((id) => this.getDispensaryById(id).pipe(take(1))));
	}
}
