export const budtender = 'budtender' as const;
export const manager = 'manager' as const;
export const buyer = 'buyer' as const;
export const other = 'other' as const;
export const demo = 'demo' as const;

export const leafJobTypes = [budtender, manager, buyer, other, demo] as const;
export const activeLeafJobTypes = [budtender, manager, buyer] as const;
export type LeafJobType = typeof leafJobTypes[number];

export const distributor = 'distributor' as const;
export const ambassador = 'ambassador' as const;
export const onpremise = 'onpremise' as const;
export const offpremise = 'offpremise' as const;

export const cellarJobTypes = [distributor, ambassador, demo, onpremise, offpremise] as const;
export const activeCellarJobTypes = cellarJobTypes;
export type CellarJobType = typeof cellarJobTypes[number];

export const beautyAdvisor = 'beautyAdvisor' as const;
export const brandRepresentative = 'brandRepresentative' as const;

export const blushJobTypes = [beautyAdvisor, brandRepresentative, demo] as const;
export const activeBlushJobTypes = blushJobTypes;
export type BlushJobType = typeof blushJobTypes[number];

export type AllJobTypes = BlushJobType | CellarJobType | LeafJobType;

export type ActiveJobType = typeof activeBlushJobTypes[number];
export const activeRoles = activeBlushJobTypes;
